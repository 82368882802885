import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import { TeamMembersQuery } from '../../../graphql-types';

type TeamMemberNode = TeamMembersQuery['allStrapiTeamMember']['edges'][0]['node'];

export class TeamMember {
  id: string;
  name: string;
  role: string;
  joined: number;

  biography?: string;

  private _photo?: ImageDataLike;

  get photo() {
    return getImage(this._photo!);
  }

  constructor(private source: TeamMemberNode) {
    this.id = source.id;
    this.name = source.name!;
    this.role = source.role!;
    this.joined = source.joined!;

    if (source.childStrapiTeamMemberBiographyTextnode?.childMdx?.body) {
      this.biography = source.childStrapiTeamMemberBiographyTextnode.childMdx.body;
    }

    if (source.photo?.localFile) {
      this._photo = source.photo.localFile as ImageDataLike;
    }
  }
}
