import { JobListingQuery } from '../../../graphql-types';

export type JobListNode = JobListingQuery['allStrapiJobListing']['edges'][0]['node'];
export type JobLocationType = 'In Office' | 'Remote';
export type JobHours = 'Full-time' | 'Part-time';

export class JobItem {
  id: string;
  title: string;
  description?: string;

  type: JobLocationType;
  hours: JobHours;

  emailOrUrl: string;
  location: string;

  publishedAt: Date;

  constructor(private source: JobListNode) {
    this.id = source.id;
    this.title = source.title!;

    this.type = source.type! as JobLocationType;
    this.hours = source.hours! as JobHours;

    this.emailOrUrl = source.emailOrUrl!;
    this.location = source.location!;

    this.publishedAt = new Date(source.publishedAt!);

    if (source.childStrapiJobListingDescriptionTextnode?.childMdx?.body) {
      this.description = source.childStrapiJobListingDescriptionTextnode?.childMdx?.body;
    }
  }
}

export interface JobItemProps {
  jobItem?: JobItem;
}
