import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { TeamMembersQuery } from '../../../../../graphql-types';
import { SectionSubheading } from '../../../section-subheading';
import SectionTitle from '../../../section-title';
import TeamMemberView from '../../../team-member';
import { TeamMember } from '../../../team-member/models';
import * as style from './index.module.scss';

export default function TeamMembers() {
  const membersData = useStaticQuery<TeamMembersQuery>(teamMemberQuery);
  const members = membersData.allStrapiTeamMember.edges.map((item) => new TeamMember(item.node));

  return (
    <section className={style.teamMembers}>
      <div className="wrapper container">
        <header className="d-flex flex-column">
          <SectionSubheading>You Should Be Here</SectionSubheading>
          <SectionTitle>
            Meet some of our <br />
            rising stars
          </SectionTitle>
        </header>
        <ul className={style.members}>
          {members.map((member, index) => (
            <li key={member.id}>
              <TeamMemberView member={member} align={index % 2 ? 'right' : 'left'} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

const teamMemberQuery = graphql`
  query TeamMembers {
    allStrapiTeamMember {
      edges {
        node {
          name
          joined(formatString: "YYYY")
          role
          id
          childStrapiTeamMemberBiographyTextnode {
            childMdx {
              body
            }
          }
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  aspectRatio: 0.75
                  layout: CONSTRAINED
                  height: 600
                )
              }
            }
          }
        }
      }
    }
  }
`;
