import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';
import { AnythingButAvgArtQuery } from '../../../../../graphql-types';
import HighlightSection from '../../../highlight-section';

export default function AnythingButAvg() {
  const bgImage = useStaticQuery<AnythingButAvgArtQuery>(dataQuery);

  return (
    <HighlightSection
      title={
        <>
          Anything
          <br />
          but average
        </>
      }
      description={
        <>
          75% of our team members smash their <br />
          monthly individual goals.
        </>
      }
      stat="75%"
      image={bgImage.file as unknown as ImageDataLike}
    />
  );
}

const dataQuery = graphql`
  query AnythingButAvgArt {
    file(relativePath: { glob: "photos/invest-in-group.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.5, width: 1500)
      }
      relativePath
    }
  }
`;
