import { motion } from 'framer-motion';
import React, { ForwardedRef, forwardRef } from 'react';
import { useInView } from 'react-intersection-observer';
import useMedia, { mediaBreakpoints } from '../../hooks/use-media';
import PerspectiveCard from '../perspective-card';
import RoundBtn from '../round-btn';
import { MotionSectionDescription } from '../section-description';
import { MotionSectionHeading } from '../section-heading';
import { MotionSectionSubheading } from '../section-subheading';
import { MotionSectionTitle } from '../section-title';
import * as style from './index.module.scss';
import { FeatureSectionItem } from './model';

export interface ItemProps {
  item: FeatureSectionItem;
}

const { sm, md } = mediaBreakpoints;

export const FeatureSectionItemView = forwardRef(
  ({ item }: ItemProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [containerRef, inView] = useInView({ threshold: 0.15 });
    const size = useMedia<'small' | 'large'>([md, sm], ['large', 'small'], 'small');

    const variants = {
      visible: {
        opacity: 1,
        transition: { duration: 0.1, staggerChildren: 0.2 }
      },
      invisible: {
        opacity: 0.98
      }
    };

    const childVariants = {
      visible: {
        opacity: 1,
        translateY: '0%',
        transition: { duration: 0.4 }
      },
      invisible: {
        opacity: 0,
        translateY: '100%'
      }
    };

    let animation = inView ? 'visible' : 'invisible';

    const art = <div className="col-12 col-md-6">{item.art}</div>;
    const content = (
      <motion.div
        ref={containerRef}
        className="mt-4 my-lg-0 col-12 col-md-6"
        variants={variants}
        animate={animation}
      >
        {item.subtitle && <MotionSectionSubheading text={item.subtitle} variants={childVariants} />}
        {item.largeTitle ? (
          <MotionSectionTitle key={item.subtitle} variants={childVariants}>
            {item.title}
          </MotionSectionTitle>
        ) : (
          <MotionSectionHeading key={item.subtitle} variants={childVariants}>
            {item.title}
          </MotionSectionHeading>
        )}
        <MotionSectionDescription variants={childVariants}>
          {item.description}
        </MotionSectionDescription>
        {/* {size === 'small' ? <>{art}</> : ''} */}
        <div className="d-md-none">{art}</div>
        <motion.div
          className={`${style.actions} my-4 d-flex flex-column flex-lg-row`}
          variants={childVariants}
        >
          {item.actions.map((action) => (
            <PerspectiveCard key={action.label} className={style.perspectiveCard} intensity={25}>
              <RoundBtn
                key={action.label}
                className={action.isPrimary ? style.primaryBtn : style.defaultBtn}
                href={action.href}
                hrefTarget={action.hrefTarget}
              >
                {action.label}
              </RoundBtn>
            </PerspectiveCard>
          ))}
        </motion.div>
      </motion.div>
    );

    if (size === 'large') {
      if (item.align === 'right') {
        return (
          <div ref={ref} className={style.itemRow + ' my-2 my-lg-0 row align-items-center'}>
            {art}
            {content}
          </div>
        );
      } else {
        return (
          <div ref={ref} className={style.itemRow + ' my-2 my-lg-0 row align-items-center'}>
            {content}
            {art}
          </div>
        );
      }
    } else {
      return (
        <div ref={ref} className={style.itemRow + ' my-2 my-lg-0 row align-items-center'}>
          {content}
        </div>
      );
    }
  }
);

export const MotionFeatureSectionItem = motion(FeatureSectionItemView);
export default FeatureSectionItemView;
