import React from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import { FeatureSectionItemAction } from '../feature-section/model';
import PerspectiveCard from '../perspective-card';
import RoundBtn from '../round-btn';
import VisualDesc from '../visual-description';
import VisualTitle from '../visual-title';
import * as style from './index.module.scss';

export interface BannerSectionProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
  actionButtonItems?: FeatureSectionItemAction[];
}

export default function BannerSection(props: StylableProp<BannerSectionProps>) {
  return (
    <section
      className={`${style.banner} d-block overflow-hidden position-relative m-auto ${props.className}`}
    >
      <div className="container position-relative h-100 d-flex flex-column align-items-center justify-content-start justify-content-md-center">
        <div
          className={
            style.content +
            ' text-center d-flex flex-column align-items-center justify-content-center'
          }
        >
          <VisualTitle className={' mb-3 mb-md-4'}>{props.title}</VisualTitle>
          <VisualDesc>{props.description}</VisualDesc>

          {props.actionButtonItems && (
            <div
              className={`${style.actions} d-flex align-self-stretch align-self-sm-center flex-column flex-lg-row`}
            >
              {props.actionButtonItems.map((action) => (
                <PerspectiveCard
                  key={action.label}
                  className={style.perspectiveCard}
                  intensity={25}
                >
                  <RoundBtn
                    key={action.label}
                    className={action.isPrimary ? style.primaryBtn : style.defaultBtn}
                    href={action.href}
                    isPrimary={action.isPrimary}
                  >
                    {action.label}
                  </RoundBtn>
                </PerspectiveCard>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
