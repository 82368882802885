import React, { ForwardedRef, forwardRef, PropsWithChildren, Ref } from 'react';
import { useInView } from 'react-intersection-observer';
import { StylableProp } from '../../utils/stylable-prop';
import { MotionSectionTitle } from '../section-title';
import * as style from './index.module.scss';
import FeatureSectionItemView from './item';
import { FeatureSectionProps } from './model';

export const FeatureSection = forwardRef(
  (
    props: StylableProp<PropsWithChildren<FeatureSectionProps>>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const variants = {
      visible: {
        opacity: 1,
        translateY: '0%',
        transition: { duration: 0.4 }
      },
      invisible: {
        opacity: 0,
        translateY: '100%'
      }
    };

    const [sectionRef, sectionInView] = useInView({ threshold: 0.1 });

    return (
      <section
        ref={sectionRef}
        className={`${style.section} d-block d-md-flex flex-column align-items-center justify-content-center ${props.className}`}
      >
        <div className={style.wrapper + ' container d-flex flex-column justify-content-center'}>
          {props.title && (
            <div className="row">
              <header
                className={
                  props.fullLengthTitle ? 'col-12 col-md-10 offset-md-1 text-center' : 'col-md-6'
                }
              >
                <MotionSectionTitle
                  variants={variants}
                  animate={sectionInView ? 'visible' : 'invisible'}
                >
                  {props.title}
                </MotionSectionTitle>
              </header>
            </div>
          )}

          {props.items.map((item, index) => (
            <FeatureSectionItemView
              ref={item.ref as Ref<HTMLDivElement>}
              key={`item-${index}`}
              item={item}
            />
          ))}
        </div>
      </section>
    );
  }
);

export default FeatureSection;
