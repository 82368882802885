import { motion, MotionStyle, useMotionValue, useTransform } from 'framer-motion';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { parseSrcset } from 'srcset';
import useMedia, { mediaBreakpoints } from '../../hooks/use-media';
import { useViewportIntersection } from '../../hooks/use-viewport-intersection';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';

export interface ParallaxBackgroundProps {
  image?: ImageDataLike;
  parentRatio?: number;
}

const { xs, md, lg } = mediaBreakpoints;
type ImgSize = 'sm' | 'md' | 'lg';

export const ParallaxBackground = (props: StylableProp<ParallaxBackgroundProps>) => {
  type T = HTMLDivElement;
  const [bgRef, bgRatio] = useViewportIntersection<T>();
  let localRef: { ref?: RefObject<T> } = { ref: bgRef };

  if (props.parentRatio) {
    localRef = {};
  }

  const imgData: ImageDataLike | undefined = props.image && getImage(props.image);

  const imgSize = useMedia<ImgSize>([lg, md, xs], ['lg', 'md', 'sm'], 'sm');
  const scrollPos = useMotionValue(0);
  const translateY = useTransform(scrollPos, [-1, 0, 1], ['0%', '50%', '100%']);
  const imgCollection = useRef<string[]>([]);
  const [bgImg, setBgImg] = useState<string | undefined>(imgData?.placeholder?.fallback);

  useEffect(() => scrollPos.set(props.parentRatio ?? bgRatio), [props.parentRatio, bgRatio]);

  if (imgData?.images.sources?.[0]) {
    imgCollection.current = extractImages(imgData.images.sources[0].srcSet);
  }

  useEffect(() => {
    let img = '';

    switch (imgSize) {
      case 'sm':
        img = imgCollection.current[0];
        break;

      case 'lg':
        img = imgCollection.current[imgCollection.current.length - 1];
        break;

      case 'md':
        if (imgCollection.current[1]) {
          img = imgCollection.current[1];
        } else {
          img = imgCollection.current[0];
        }

        break;
    }

    if (img) {
      loadImage(img)
        .then(() => setBgImg(img))
        .catch((e) => console.error(`Couldn't load image: ${img}`));
    }
  }, [imgSize, bgImg]);

  const styles: MotionStyle = {
    backgroundPositionY: translateY
  };

  if (bgImg) {
    styles.backgroundImage = `url(${bgImg})`;
  }

  return (
    <motion.div {...localRef} className={`${style.background} ${props.className}`} style={styles} />
  );
};

const extractImages = (source: string) => {
  return parseSrcset(source)
    .sort((a, b) => a.width! - b.width!)
    .map((s) => s.url);
};

const loadImage = async (url: string) => {
  return new Promise<void>((resolve, reject) => {
    if (!url) {
      return reject();
    }

    const image = new Image();
    image.src = url;
    image.addEventListener('load', () => resolve());
  });
};

export default ParallaxBackground;
