import { GatsbyImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import * as style from './index.module.scss';
import { TeamMember } from './models';

interface TeamMemberViewProps {
  member: TeamMember;
  align?: 'left' | 'right';
}

export default function TeamMemberView(props: TeamMemberViewProps) {
  const member = props.member;

  return (
    <div
      className={`${style.teamMember} d-flex align-items-center ${
        props.align === 'right' ? 'flex-row-reverse' : ''
      }`}
    >
      <div className={`${style.details} ${props.align === 'right' ? style.right : ''}`}>
        <header className="d-flex align-items-center justify-content-center">
          <div className={`${style.photo} d-flex d-md-none flex-column align-items-center`}>
            {member.photo && <GatsbyImage image={member.photo} alt={`${member.name}'s Photo`} />}
          </div>

          <p className="mx-2 mx-md-0 flex-grow-1">
            {member.name} <br />
            Member since {member.joined} <br />
            {member.role}
          </p>
        </header>

        <div className={style.biography}>
          <MDXRenderer>{member.biography!}</MDXRenderer>
        </div>
      </div>

      <div className={style.spacer + ' d-none d-xl-block'}></div>

      <div className={`${style.photo} d-none d-md-flex flex-column align-items-center`}>
        {member.photo && <GatsbyImage image={member.photo} alt={`${member.name}'s Photo`} />}
      </div>
    </div>
  );
}
