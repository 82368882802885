import React from 'react';
import VisualDesc from '../../../visual-description';
import VisualTitle from '../../../visual-title';
import * as style from './index.module.scss';

export function NewToIndustry() {
  return (
    <section
      className={
        style.testimonials +
        ' m-auto d-block d-md-flex flex-column align-items-center justify-content-center'
      }
    >
      <div className={style.wrapper + ' container d-md-flex flex-column align-items-center'}>
        <VisualTitle className={`${style.title} text-md-center`}>
          New to the
          <br />
          mortgage industry?
        </VisualTitle>

        <VisualDesc>
          If you are looking for ordinary, stuffy, and old-fashioned , then you need to avoid
          Nuborrow. we are serious, just keep scrolling.
          <br />
          <br />
          If, however, you are looking for an entry-level position (more than half of our staff
          started with no experience) with unlimited growth potential then we are looking for you.
          <br />
          <br />
          We focus on continuous growth, improvement, and personal goal setting then we do on
          numbers. If you have a real passion for a customer-first service level we believe we can
          train the rest.
        </VisualDesc>
      </div>
    </section>
  );
}
