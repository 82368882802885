import React, { useState } from 'react';
import Logo from '../logo';
import RoundBtn from '../round-btn';
import SectionTitle from '../section-title';
import * as style from './index.module.scss';

const CONTACT_US_API = 'https://nu2morrow.com/crm/apis/user/contact_us';

export default function ContactSection() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [sent, setSent] = useState(false);

  return (
    <section
      id={'contactus'}
      className={' m-auto d-block d-md-flex flex-column align-items-center justify-content-center'}
    >
      <div className={style.fluidContainer + ' container-fluid'}>
        <div className="container d-flex flex-column flex-md-row">
          <div className={style.column + ' content d-flex flex-column justify-content-between'}>
            <Logo width={250} className={style.logo + ' d-none d-md-block'} />
            <SectionTitle className={style.title + ' text-white'}>
              We love to hear from our fans.
            </SectionTitle>
          </div>
          <div className={style.spacer}></div>
          <div className={style.column + ' contact-form d-flex flex-column'}>
            <p>
              Nuborrow Head Office <br />
              9135 Keele St. Unit B1 Vaughan, On L4K 1J0 <br />
              Phone: +1 888 372 5588 <br />
              Email: hello@nuborrow.com <br />
              FSRA Lic # 13115
            </p>
            {/* talk2us@nuborrow.com */}
            {!sent ? (
              <form
                className={style.contactForm}
                onSubmit={async (e) => {
                  e.preventDefault();

                  const data = new FormData();
                  data.append('name', name);
                  data.append('email', email);
                  data.append('phone', phone);
                  data.append('message', message);

                  const result = await fetch(CONTACT_US_API, {
                    method: 'POST',
                    body: data
                  });

                  if (!result.ok) {
                    alert('There was a problem sending your message.');
                    return;
                  }

                  setSent(true);
                }}
              >
                <div className={style.field}>
                  <input
                    required
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                    placeholder="name:"
                  />
                </div>
                <div className={style.field}>
                  <input
                    required
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    placeholder="email:"
                  />
                </div>
                <div className={style.field}>
                  <input
                    required
                    type="tel"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.currentTarget.value)}
                    placeholder="phone:"
                  />
                </div>
                <div className={style.field}>
                  <textarea
                    required
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.currentTarget.value)}
                    placeholder="message:"
                    minLength={10}
                    rows={5}
                  ></textarea>
                </div>
                <RoundBtn className={style.submitBtn} type="submit">
                  Talk to us
                </RoundBtn>
              </form>
            ) : (
              <p className={style.success}>
                Thank you <b>{name}</b> for reaching out.
                <br />
                <br /> Someone from our team will contact you shortly.
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
