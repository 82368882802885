import { ImageDataLike } from 'gatsby-plugin-image';
import React, { ReactNode } from 'react';
import ParallaxBackground from '../parallax-background';
import { SectionDescription } from '../section-description';
import SectionTitle from '../section-title';
import * as style from './index.module.scss';

interface HighlightSectionProps {
  title: string | ReactNode;
  description: string | ReactNode;
  stat: string;
  image: ImageDataLike;

  compact?: boolean;
}

export default function HighlightSection(props: HighlightSectionProps) {
  return (
    <section
      className={
        style.highlightSection +
        ' d-flex flex-column align-items-center justify-content-center'
      }
    >
      <div className={style.wrapper + ' container'}>
        <div className={style.artContainer}>
          <ParallaxBackground className={style.heroImage} image={props.image} />
          <div className={`${style.statBlock} ${props.compact ? style.compact : ''}`}>
            <span>{props.stat}</span>
          </div>
        </div>

        <div className={style.details + ' px-2'}>
          <SectionTitle className={style.title}>
            {props.title}
          </SectionTitle>
          <SectionDescription>{props.description}</SectionDescription>
        </div>
      </div>
    </section>
  );
}
