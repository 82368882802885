import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';
import { InvestingInTeamQuery } from '../../../../../graphql-types';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
import ParallaxBackground from '../../../parallax-background';
import * as style from './index.module.scss';

export default function InvestingInTeam() {
  const data: FeatureSectionProps = {
    items: [
      {
        title: 'Investing in our team is a top priority.',
        subtitle: 'Set growth to hyper speed',
        description: (
          <>
            Since 2014 we've seen tremendous growth in Nuborrow, but it is our team, our
            environment, and our company culture that we are so proud of.
            <br />
            <br />
            If your are interested in both career and personal growth then come join our growing
            team of people first, people.
          </>
        ),
        actions: [],
        art: investingInTeamArt()
      }
    ]
  };

  return <FeatureSection {...data} />;
}

const investingInTeamArt = () => {
  const bgImage = useStaticQuery<InvestingInTeamQuery>(dataQuery);

  return (
    <div className={'d-flex align-items-center justify-content-center'}>
      <ParallaxBackground
        className={style.investingInTeamArt}
        image={bgImage.file as unknown as ImageDataLike}
      />
    </div>
  );
};

const dataQuery = graphql`
  query InvestingInTeam {
    file(relativePath: { glob: "photos/equity-into-cash.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.625, width: 1000)
      }
      relativePath
    }
  }
`;
