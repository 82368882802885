import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { StylableProp } from '../../../utils/stylable-prop';
import { SectionHeading } from '../../section-heading';
import { JobItemProps } from '../models';
import * as style from './index.module.scss';

export default function JobItemListing(props: StylableProp<JobItemProps>) {
  const jobItem = props.jobItem;

  if (!jobItem) {
    return <></>;
  }

  return (
    <div className={style.jobItem}>
      <SectionHeading compact={true} className={style.heading}>
        {jobItem.title}
      </SectionHeading>

      <div className={style.meta}>
        <span className="location">{jobItem.location}</span> |{' '}
        <span className="locationType">{jobItem.type}</span> |{' '}
        <span className="hours">{jobItem.hours}</span>
      </div>

      <MDXRenderer>{jobItem.description!}</MDXRenderer>
    </div>
  );
}
