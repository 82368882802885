import { motion } from 'framer-motion';
import React, { ForwardedRef, forwardRef, PropsWithChildren } from 'react';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.scss';

export const BasicCard = forwardRef<HTMLDivElement, StylableProp<PropsWithChildren<unknown>>>(
  (props: StylableProp<PropsWithChildren<unknown>>, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div ref={ref} className={`${style.card} ${props.className} `}>
        {props.children}
      </div>
    );
  }
);

export const MotionBasicCard = motion<StylableProp<PropsWithChildren<unknown>>>(BasicCard);
export default BasicCard;
