import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';
import { AnythingButAvgArtQuery } from '../../../../../graphql-types';
import HighlightSection from '../../../highlight-section';

export default function MoreThanJob() {
  const bgImage = useStaticQuery<AnythingButAvgArtQuery>(dataQuery);

  return (
    <HighlightSection
      title={
        <>
          More than <br />
          just a job
        </>
      }
      description={
        <>
          At Nuborrow.com, we're all about personal
          <br />
          growth and relationships.
        </>
      }
      stat="Grow fast."
      compact={true}
      image={bgImage.file as unknown as ImageDataLike}
    />
  );
}

const dataQuery = graphql`
  query MoreThanJobArt {
    file(relativePath: { glob: "photos/more-than-job.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.5, width: 1500)
      }
      relativePath
    }
  }
`;
