import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { JobListingQuery } from '../../../../graphql-types';
import BasicCard from '../../basic-card';
import RoundBtn from '../../round-btn';
import JobItemListing from '../item';
import { JobItem } from '../models';
import * as style from './index.module.scss';

export default function JobListing() {
  const queryResult = useStaticQuery<JobListingQuery>(listingQuery);
  const listing = queryResult.allStrapiJobListing.edges;

  const [showModal, setShowModal] = useState(false);
  const [jobItem, setJobItem] = useState<JobItem>();

  return (
    <section id="listing" className={`${style.listingSection} d-block position-relative m-auto`}>
      <div className="container position-relative h-100 d-flex flex-column align-items-center justify-content-center">
        <ul className={`${style.list}`}>
          {listing.map((job) => (
            <li key={job.node.id}>
              <BasicCard className={`${style.card} h-100 p-4`}>
                <div className="content d-flex flex-column">
                  <h3>{job.node.title}</h3>
                  <span className="location">{job.node.location}</span>
                  <div className={style.spacer}></div>
                  <button
                    className="action-btn"
                    onClick={() => {
                      setShowModal(true);
                      setJobItem(new JobItem(job.node));
                    }}
                  >
                    View Opportunity →
                  </button>
                </div>
              </BasicCard>
            </li>
          ))}
        </ul>
      </div>
      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            zIndex: 10000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backdropFilter: 'blur(5px)'
          },
          content: {
            width: '800px',
            height: 'auto',
            margin: '0 auto',
            top: 'unset',
            bottom: 'unset',
            left: 'unset',
            right: 'unset',
            position: 'relative',
            border: 'none',
            borderRadius: '16px',
            boxShadow: '0 30px 80px -10px rgb(0 0 0 / 50%)'
          }
        }}
      >
        <JobItemListing jobItem={jobItem} />
        <div className={`${style.actions} d-flex`}>
          <RoundBtn href={jobItem?.emailOrUrl} hrefTarget="_blank" isPrimary={true} height={2.5}>
            Join the Team
          </RoundBtn>
          <RoundBtn onClick={() => setShowModal(false)} isPrimary={false} height={2.5}>
            Go Back
          </RoundBtn>
        </div>
      </ReactModal>
    </section>
  );
}

const listingQuery = graphql`
  query JobListing {
    allStrapiJobListing(sort: { order: DESC, fields: updatedAt }) {
      edges {
        node {
          id
          title
          type
          publishedAt
          emailOrUrl
          hours
          location
          childStrapiJobListingDescriptionTextnode {
            childMdx {
              body
            }
          }
        }
      }
    }
  }
`;
