import React from 'react';
import BannerSection from '../components/banner-section';
import CallToAction from '../components/call-to-action';
import { CallToActionCardProps } from '../components/call-to-action/models';
import ContactSection from '../components/contact-section';
import { FeatureSectionItemAction } from '../components/feature-section/model';
import JobListing from '../components/job-listing/listing';
import Layout from '../components/layout';
import AnythingButAvg from '../components/sections/careers/anything-but-avg';
import InvestingInTeam from '../components/sections/careers/investing-in-team';
import MoreThanJob from '../components/sections/careers/more-than-job';
import { NewToIndustry } from '../components/sections/careers/new-to-industry';
import TeamMembers from '../components/sections/careers/team-members';

export default function CareersPage() {
  const bannerTitle = `Calling all people lovers`;
  const bannerDesc = (
    <>
      We're looking for passionate people, who love to help others, and think time well spent is
      figuring out how to repair another human's financial education, health, or both!
    </>
  );

  const bannerAction: FeatureSectionItemAction[] = [
    {
      label: 'Join the team',
      href: '/care',
      isPrimary: true,
      hrefTarget: '_blank'
    }
  ];

  const ctaTitle = <>Join the Nuborrow team.</>;

  const callToActionCards: CallToActionCardProps[] = [
    {
      title: 'Join a winning team.',
      actionLabel: 'Start your new career',
      actionHref: '/careers#listing'
    },
    {
      title: 'Want to know more about Nuborrow?',
      actionLabel: 'Press Room',
      actionHref: '/soon#news'
    }
  ];

  return (
    <Layout pageTitle={'Careers'}>
      <BannerSection
        title={bannerTitle}
        description={bannerDesc}
        actionButtonItems={bannerAction}
      />

      <InvestingInTeam />

      <AnythingButAvg />

      <NewToIndustry />
      <JobListing />

      <TeamMembers />

      <MoreThanJob />

      <ContactSection />

      <CallToAction title={ctaTitle} cards={callToActionCards} />
    </Layout>
  );
}
